<template>
  <div class="content-box">
    <h5 class="title_box">绑定</h5>
    <div class="item">
      <input v-model="tel" type="tel" placeholder="请输入手机号" />
    </div>
    <div class="item" style="margin-top: 10px">
      <div class="dunxin">
        <input type="tel" v-model="sms" placeholder="请输入短信验证码" />
        <van-button :disabled="disBtn" class="btn-box" size="small" @click="sendCode" type="primary">{{
          disBtnText
        }}</van-button>
      </div>
    </div>
    <div style="margin-top: 80px" class="btn" @click="submitForm">确认</div>

    <!--    <div class="footer">-->
    <!--      <div class="btn" @click="submitForm">确定提交</div>-->
    <!--    </div>-->
  </div>
</template>

<script>
/* eslint-disable */
import { Toast } from 'vant'
import axios from 'axios'
export default {
  name: 'register',
  data() {
    return {
      value: '',
      tel: '',
      sms: '',
      carNum: '',
      radio: '',
      company: '',
      activeIcon: 'https://img01.yzcdn.cn/vant/user-active.png',
      inactiveIcon: 'https://img01.yzcdn.cn/vant/user-inactive.png',
      date: '',
      show: false,
      showGs: false,
      actions: [{ name: '选项一' }, { name: '选项二' }, { name: '选项三' }],
      disBtn: false,
      disBtnText: '发送验证码',
      isShowTel: true,
    }
  },
  mounted() {},
  methods: {
    sendCode() {
      if (this.disBtn) {
        return
      } else {
        axios.get(httpUrl + `/fs/sc/phone/code?phone=${this.tel}`).then((res) => {
          this.disBtn = true
          let times = 60
          let time = setInterval(() => {
            if (times > 0) {
              this.disBtnText = `${times}s后重试`
              times--
            } else {
              this.disBtn = false
              this.disBtnText = `发送验证码`
              clearInterval(time)
            }
          }, 1000)
        })
      }
    },
    submitForm() {
      const that = this
      localStorage.setItem('iphone', this.tel)
      localStorage.setItem('fsId', that.$route.query.fsId)
      localStorage.setItem('type', that.$route.query.type)
      axios.get(httpUrl + `/fs/sc/verification/code?code=${this.sms}&phone=${this.tel}`).then((res) => {
        if ((res.msg = 200)) {
          debugger
          this.$router.push({
            path: '/register',
            query: {
              phone: that.tel,
              fsId: that.$route.query.fsId ? this.$route.query.fsId : '',
              code: that.$route.query.code,
              type: that.$route.query.type,
            },
          })
        }
      })
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onConfirm(date) {
      console.log(date)
      this.show = false
      this.date = this.formatDate(date)
    },
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.showGs = false
      this.company = item.name
    },
  },
}
</script>

<style scoped>
.title_box {
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  padding: 20px 20px 40px;
}
.item {
  background: #fff;
  padding: 10px 0 0;
  width: 93%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #ddd;
}
.item input {
  width: 100%;
  font-size: 14px;
  padding: 15px 10px;
}
.item h5 {
  margin-bottom: 15px;
  text-align: left;
  font-size: 18px;
}
.dunxin {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dunxin input {
  font-size: 14px;
  padding: 15px 10px;
  width: 50%;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #ccc;
}
.img-icon {
  height: 20px;
}
/deep/ .van-calendar__selected-day {
  background: #2e82fe;
}
._boxtext {
  padding: 10px 8px;
  margin-right: 10px;
}
/deep/ .form_date .van-cell__title {
  text-align: left;
  flex: inherit;
  padding-right: 30px;
  font-size: 13px;
}
/deep/ .van-button--danger {
  background-color: #2e82fe;
  border: 1px solid #2e82fe;
}
/deep/ .form_date .van-cell__value {
  text-align: left;
  color: #323232;
}
/deep/ .btn-box {
  background: #fff;
  border: none;
  color: #2e82fe;
}
/deep/ .form_date_box .van-cell__title {
  text-align: left;
  flex: inherit;
  padding-right: 30px;
  font-size: 13px;
}
/deep/ .form_date_box .van-cell__value {
  text-align: left;
  color: #323232;
}
.content-box {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  /*justify-content: center;*/
  height: 100vh;
  background: #fff;
}
.is_box {
  background: #fff;
  height: 44px;
  display: flex;
  align-items: center;
}
.footer {
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  background: #fff;
  padding-top: 20px;
}
.btn {
  width: 87%;
  margin: 0 auto;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2e82fe;
  color: #fff;
  font-size: 16px;
}
</style>
