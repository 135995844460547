<template>
  <div class="content-box">
    <van-cell-group>
      <van-field required v-model="value" label="姓名" placeholder="请输入姓名" />
      <van-field required v-model="carNum" label="车牌号" placeholder="请输入车牌号" />
    </van-cell-group>
    <div style="display: flex;font-size: 13px" class="is_box van-hairline--bottom">
      <span class="_boxtext"><i style="color:#ff0000;">* </i>是否为临时牌照</span
      ><van-radio-group v-model="radio" direction="horizontal">
        <van-radio name="0">是</van-radio>
        <van-radio name="1">否</van-radio>
      </van-radio-group>
    </div>
    <van-cell is-link class="form_date_box" title="车辆品牌" :value="cbName" @click="carNameShow = true" />
    <van-cell is-link class="form_date_box" title="保险公司" :value="icName" @click="showGs = true" />
    <van-action-sheet v-model="showGs" :actions="actions" @select="onSelect" />
    <van-cell-group>
      <van-cell is-link class="form_date" title="保险到期日期" :value="date" @click="show = true" />
      <van-calendar :calendar-range-edge-background-color="'blue'" v-model="show" @confirm="onConfirm" />
    </van-cell-group>
    <van-action-sheet v-model="carNameShow" :actions="carType" @select="onSelects" />
    <div class="footer">
      <div class="btn" style="background: #fff;border: 1px solid #2E82FE;color: #2E82FE" @click="goUpload">去报案</div>
      <div class="btn" @click="submitForm">确定提交</div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
import axios from 'axios'
const getQueryVariable = (variable) => {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}
export default {
  name: 'register',
  data() {
    return {
      value: '',
      carNameShow: false,
      tel: '',
      sms: '',
      carNum: '',
      icName: '',
      fsId: '',
      Id: '',
      radio: '',
      activeIcon: 'https://img01.yzcdn.cn/vant/user-active.png',
      inactiveIcon: 'https://img01.yzcdn.cn/vant/user-inactive.png',
      date: '',
      cbId: '',
      cbName: '',
      show: false,
      showGs: false,
      actions: [],
      carType: [],
      disBtn: false,
      disBtnText: '发送验证码',
      isShowTel: true,
      reportOrcode: '',
    }
  },
  mounted() {
    //
    // axios.post

    const tel = localStorage.getItem('iphone')
    debugger
    if (tel || getQueryVariable('phone')) {
      const phone = tel || getQueryVariable('phone')
      const that = this
      axios.get(httpUrl + '/fs/sc/iclist').then((res) => {
        if (res.data.code === 200) {
          that.actions = res.data.data
        }
      })
      // carType
      axios.get(httpUrl + '/fs/sc/cb/list').then((res) => {
        if (res.data.code === 200) {
          that.carType = res.data.data
        }
      })
      this.tel = phone //this.$route.query.phone

      axios.get(httpUrl + `/fs/sc/customerByPhone?phone=${phone}`).then((res) => {
        if (res.data.code === 200) {
          const {
            expirationTime,
            fsId,
            icId,
            reportOrcode,
            cbName,
            cbId,
            icName,
            id,
            temporaryLicense,
            licensePlate,
            name,
            phone,
          } = res.data.data
          // this.radio = icId
          that.date = expirationTime
          that.carNum = licensePlate
          that.icName = icName
          that.fsId = fsId
          that.cbId = cbId
          that.cbName = cbName
          that.reportOrcode = reportOrcode

          that.radio = temporaryLicense.toString()
          that.value = name
          that.Id = id

          setTimeout(() => {
            if (new Date().getTime() - localStorage.getItem('time') < 5 * 60000) {
              this.submitForm()
            }
          }, 2000)
        } else {
          Toast(res.data.msg)
        }
      })
    } else {
      this.$router.push({
        path: '/beforregister',
        query: {
          fsId: this.fsId ? this.fsId : this.$route.query.fsId ? this.$route.query.fsId : '',
          code: this.$route.query.code,
          type: that.$route.query.type,
        },
      })
    }
  },
  methods: {
    onSelects(item) {
      this.cbId = item.id
      this.cbName = item.name
      this.carNameShow = false
    },
    goUpload() {
      this.$router.push({ path: '/upInfo', query: { phone: this.tel, code: this.reportOrcode } })
    },
    submitForm() {
      if (!this.tel) {
        Toast('填写手机号')
        return
      } else if (!this.radio) {
        Toast('请选择临时牌照')
        return
      } else if (!this.date) {
        Toast('请选择保险日期')
        return
      } else if (!this.icName) {
        Toast('请选择保险公司')
        return
      } else if (!this.carNum) {
        Toast('请填写车牌号')
        return
      } else if (!this.value) {
        Toast('请填写姓名')
        return
      } else if (!this.cbName) {
        Toast('请选择车辆品牌')
        return
      }

      if (this.$route.query.code) {
        let arr = {}
        if (this.$route.query.type == 0) {
          arr = { reportOrcode: this.$route.query.code }
        } else if (this.$route.query.type == 1) {
          arr = { moveOrcode: this.$route.query.code }
        }
        axios
          .post(httpUrl + '/fs/sc/iouFsCustomerOrcode', {
            expirationTime: this.date,
            cbId: this.cbId,
            cbName: this.cbName,
            fsId: getQueryVariable('fsId') || this.fsId,
            icId: this.icId,
            icName: this.icName,
            id: this.Id,
            licensePlate: this.carNum,
            name: this.value,
            phone: this.tel,
            temporaryLicense: this.radio == 1 ? 1 : 0,
            ...arr,
          })
          .then((res) => {
            if (res.data.code === 200) {
              Toast('绑定成功')
            } else {
              Toast(res.data.msg)
            }
          })
      } else {
        axios
          .post(httpUrl + '/fs/sc/iouFsCustomer', {
            expirationTime: this.date,
            cbId: this.cbId,
            cbName: this.cbName,
            fsId: getQueryVariable('fsId') || this.fsId,
            icId: this.icId,
            icName: this.icName,
            id: this.Id,
            licensePlate: this.carNum,
            name: this.value,
            phone: this.tel,
            temporaryLicense: this.radio == 1 ? 1 : 0,
          })
          .then((res) => {
            Toast('提交成功')
            setTimeout(() => {
              location.reload()
            }, 1500)
          })
      }
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    onConfirm(date) {
      this.show = false
      this.date = this.formatDate(date)
    },
    onSelect(item) {
      this.icId = item.id
      this.icName = item.name
      this.showGs = false
    },
  },
}
</script>

<style scoped>
.img-icon {
  height: 20px;
}
/deep/ .van-calendar__selected-day {
  background: #2e82fe;
}
._boxtext {
  padding: 10px 8px;
  margin-right: 10px;
}
/deep/ .form_date .van-cell__title {
  text-align: left;
  flex: inherit;
  padding-right: 30px;
  font-size: 13px;
}
/deep/ .van-button--danger {
  background-color: #2e82fe;
  border: 1px solid #2e82fe;
}
/deep/ .form_date .van-cell__value {
  text-align: left;
  color: #323232;
}
/deep/ .btn-box {
  background: #fff;
  border: 1px solid #2e82fe;
  color: #2e82fe;
}
/deep/ .form_date_box .van-cell__title {
  text-align: left;
  flex: inherit;
  padding-right: 30px;
  font-size: 13px;
}
/deep/ .form_date_box .van-cell__value {
  text-align: left;
  color: #323232;
}
.content-box {
  height: 100vh;
  background: #f5f5f5;
}
.is_box {
  background: #fff;
  height: 44px;
  display: flex;
  align-items: center;
}
.footer {
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  background: #fff;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.btn {
  width: 43%;
  margin: 0 auto;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2e82fe;
  color: #fff;
  font-size: 16px;
}
.btn-plgu {
  width: 90%;
  margin: 30px auto;
  border-radius: 30px;
}
</style>
