<template>
  <div class="wrap_box">
    <div v-if="rotatePicturesList">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in rotatePicturesList" :key="index">
          <img class="title_box_bg" :src="item.url" alt="" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <img class="title_box_dec" src="../assets/playphone.png" alt="" />
    <div class="car_num">
      {{ licensePlate }}
    </div>
    <p class="dec">我的爱车如果阻碍了您的车辆通过，您可以点击下方按钮通知我，给您带来的不便敬请谅解</p>

    <div class="footer">
      <div class="item_tel" @click="sendMsg">短信通知</div>
      <a class="item" @click="goTel" :href="'tel:' + iphone" id="tel">
        电话通知
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Dialog, Swipe, SwipeItem } from 'vant'
import axios from 'axios'
export default {
  name: 'playphone',
  components: {
    Dialog,
  },
  data() {
    return {
      showWrap: true,
      iphone: '',
      licensePlate: '',
      rotatePicturesList: '',
    }
  },
  mounted() {
    const that = this
    if (this.$route.query.type) {
      localStorage.clear()
    }
    if (this.$route.query.type == 1 || this.$route.query.type == 0) {
      axios.get(httpUrl + `/fs/sc/orcode/info?code=${this.$route.query.code}`).then((res) => {
        if (res.data.code === 200) {
          if (res.data.data.status == 0) {
            localStorage.setItem('time', new Date().getTime())
            this.$router.push({
              path: '/beforregister',
              query: {
                code: this.$route.query.code,
                type: this.$route.query.type,
                fsId: this.$route.query.fsid,
              },
            })
          } else {
            this.showWrap = false
            this.init()
          }
        }
      })
    }

    this.init()
  },
  methods: {
    goTel() {
      axios.post(httpUrl + `/fs/log`, {
        phone: this.iphone,
        title: '打电话',
      })
    },
    init() {
      const that = this
      debugger
      axios.get(httpUrl + `/fs/sc/by/code?code=${this.$route.query.code}`).then((res) => {
        if (res.data.code === 200) {
          that.iphone = res.data.data.customerPhone
          that.licensePlate = res.data.data.licensePlate
          that.rotatePicturesList = res.data.data.rotatePicturesList
        } else {
        }
      })
    },
    sendMsg() {
      axios.post(httpUrl + `/fs/log`, {
        phone: this.iphone,
        title: '发短信',
      })
      axios.get(httpUrl + `/fs/sc/move/car/sms/code?code=${this.$route.query.code}`).then((res) => {
        if (res.data.code === 200) {
          Dialog({ message: '发送成功' })
        }
      })
    },
  },
}
</script>

<style scoped>
.wrap_box {
  background: #fff;
  height: 100vh;
}
.title_box_bg {
  display: block;
  width: 92%;
  margin: 20px auto;
  border-radius: 8px;
}
.title_box_dec {
  display: block;
  width: 56.9%;
  margin: 30px auto;
}
.title {
  font-size: 22px;
  color: #fff;
  text-align: center;
  padding: 30px 0 20px;
}
.car_num {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2e82fe;
  font-size: 19px;
  padding: 3px;
  width: 157px;
  height: 50px;
  background: url(../assets/carnum.png);
  background-repeat: no-repeat;
  background-size: 98% 98%;
  margin: 0 auto;
}
.dec {
  color: #c5c4c4;
  font-size: 12px;
  padding: 3px 20px;
  margin-top: 20px;
  text-align: left;
}
.footer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
.footer .item_tel {
  font-size: 16px;
  border: 1px solid #2e82fe;
  color: #2e82fe;
  height: 20px;
  width: 100px;
  padding: 15px 23px;
  border-radius: 40px;
  text-align: center;
}
.footer .item_tel:active {
  position: relative;
  top: 2px;
  background: #2e82fe;
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  outline: 0;
  color: #fff;
}
.footer .item {
  background: #2e82fe;
  font-size: 16px;
  color: #fff;
  height: 20px;
  width: 100px;
  padding: 15px 23px;
  border-radius: 40px;
  text-align: center;
}
.footer .item:active {
  position: relative;
  top: 2px;
  background: rgb(43, 132, 231);
  box-shadow: inset 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  outline: 0;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #fff;
}
</style>
