<template>
  <div class="amap-page-container">
    <div id="container" class="amap-demo"></div>

    <div class="content">
      <div class="toolbar" @click="addMarker"></div>
      <h3 style="display: flex; justify-content: space-between">
        <span>{{ this.pageDate.licensePlate }}（{{ pageDate.cbName }}）</span>
        <span style="color: #2e82fe" @click="goArea">去导航</span>
      </h3>
      <div class="bar">
        <p>报案位置： {{ pageDate.addr }}</p>
      </div>
      <div class="item">
        <p class="left">车主电话</p>
        <p class="center">{{ pageDate.cphone }}</p>
        <p class="right">
          <a :href="'tel:' + pageDate.cphone"><img src="../assets/tel.png" alt=""/></a>
        </p>
      </div>
      <div class="item" v-if="pageDate.contactPhone">
        <p class="left">联系电话</p>
        <p class="center">{{ pageDate.contactPhone }}</p>
        <p class="right">
          <a :href="'tel:' + pageDate.contactPhone"><img src="../assets/tel.png" alt=""/></a>
        </p>
      </div>
      <div class="item" v-if="pageDate.icName">
        <p class="left">保险公司</p>
        <p class="center" style="width: 85%">{{ pageDate.icName }}&nbsp;&nbsp;({{ pageDate.expirationTime }}到期)</p>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { Toast } from 'vant'
import axios from 'axios'
import car from './../assets/car.png'
var map, geolocation, marker
export default {
  data: function() {
    return {
      x: 0,
      y: 0,
      pageDate: '',
    }
  },
  mounted() {
    map = new AMap.Map('container', {
      zoom: 16,
      resizeEnable: true,
      mapStyle: 'light',
    })
    const code = window.location.href.split('#')[1]
    this.init(code)
    // this.getMap();
  },

  methods: {
    goArea() {
      const posit = this.pageDate.longitudeAndLatitude.split(',')
      let ab = encodeURI(this.pageDate.addr)
      let a = `https://m.amap.com/navigation/index/daddr=${posit[0]},${posit[1]},${ab}`
      console.log(a)
      location.href = a
    },
    init(e) {
      const that = this
      axios.get(httpUrl + `/fs/sc/inCustomerReport?code=${e}`).then((res) => {
        if (res.data.code === 200) {
          that.pageDate = res.data.data
          let a = that.pageDate.longitudeAndLatitude.split(',')
          that.addMarker(a[0], a[1]) // 116.42073947482697,39.948637695313
        }
      })
    },
    addMarker(x, y) {
      var content = `<div style="background: url(${car}) no-repeat;width: 51px;height: 25px;position: relative">
                            <div style="display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;position:absolute;border-radius:6px;
                                left:-75px;top:-90px;background: #2E82FE;width: 200px;height: 50px">
                              <span style="display: block;font-size: 12px;color: #fff">${this.pageDate.addr}</span>
                              <span style="display: block;font-size: 12px;color: #fff">${this.pageDate.createTime}</span>
                              <p style="position: absolute;left: 100px;top: 50px;width: 3px;height: 30px;display:inline-block;background: #2E82FE;">
                              <i style="left:-2px;display: inline-block;width: 7px;height: 7px;border-radius: 7px;position: absolute;bottom: 0;background: #2E82FE;"></i></p>
                            </div>
                        </div>`
      marker = new AMap.Marker({
        content,
        // icon: car,
        // size: new AMap.Size(40, 50),
        // imageSize: new AMap.Size(40, 50),
        position: [x, y],
        offset: new AMap.Pixel(-13, -30),
      })
      marker.setMap(map)
      map.setCenter([x, y])
    },
    getMap() {
      const that = this
      Toast.loading({
        message: '定位中...',
        forbidClick: true,
      })
      var options = {
        showButton: true, //是否显示定位按钮
        buttonPosition: 'LB', //定位按钮的位置
        /* LT LB RT RB */
        showMarker: true, //是否显示定位点
        markerOptions: {
          //自定义定位点样式，同Marker的Options
          offset: new AMap.Pixel(-18, -36),
          content: `
              <div style="position: relative;width: 1px;height: 1px">
                <div style="display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;position:absolute;border-radius:6px;left:-100px;top:-100px;background: #2E82FE;width: 200px;height: 50px">
                  <span style="display: block;font-size: 12px;color: #fff">新湖大厦</span>
                  <span style="display: block;font-size: 12px;color: #fff">2021-09-01</span>
                  <p style="position: absolute;left: 100px;top: 50px;width: 3px;height: 30px;display:inline-block;background: #2E82FE;">
                  <i style="left:-2px;display: inline-block;width: 7px;height: 7px;border-radius: 7px;position: absolute;bottom: 0;background: #2E82FE;"></i></p>
                </div>
                <img src= ${car}
                style="position:absolute;left:-36px;top:-18px;width:78px;height:36px"/>
              </div>
            `,
        },
        zoomToAccuracy: true,
        showCircle: false, //是否显示定位精度圈
      }
      AMap.plugin(['AMap.Geolocation'], function() {
        var geolocation = new AMap.Geolocation(options)
        map.addControl(geolocation)
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete) //返回定位信息
        AMap.event.addListener(geolocation, 'error', onError) //返回定位出错信息
      })
      // //解析定位结果
      function onComplete(data) {
        var str = []
        str.push(data.position.getLat())
        str.push(data.position.getLng())
        Toast.clear()
      }
      //解析定位错误信息
      function onError(data) {
        alert('定位失败')
        Toast.clear()
      }
    },
  },
}
</script>
<style scoped>
.amap-demo {
  height: 100vh !important;
  width: 100vw !important;
}
.toolbar {
  position: absolute;
  background: url(../assets/icon.png);
  background-size: 100% 100%;
  top: -1.17rem;
  width: 1.07rem;
  height: 1.07rem;
  right: 0;
}
.content {
  position: absolute;
  left: 3%;
  box-sizing: border-box;
  z-index: 99999;
  width: 94%;
  background: #fff;
  border-radius: 12px;
  bottom: 25px;
  font-size: 14px;
  padding: 20px;
}
.content h3 {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 4px;
}
.content .bar {
  display: flex;
  justify-content: space-between;
}
.content .bar p {
  text-align: left;
  font-size: 16px;
}
.posi-box {
  width: 79px;
  height: 16px;
}
.item {
  display: flex;
  font-size: 14px;
  padding-top: 8px;
  border-top: 1px solid #efefef;
  margin-top: 8px;
  align-items: center;
}
.item .left {
  font-size: 16px;
  width: 25%;
  text-align: left;
}
.item .center {
  font-size: 16px;
  width: 55%;
}
.item .right {
  font-size: 16px;
  width: 20%;
  text-align: right;
}
.item .right img {
  width: 27px;
  height: 27px;
}
</style>
