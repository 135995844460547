<template>
  <div class="amap-page-container">
    <div id="container" class="amap-demo"></div>

    <div class="content">
      <!--      <div class="toolbar" @click="getMap">-->
      <div class="toolbar" @click="addMarker"></div>
      <h3 v-if="positionDate">
        {{ positionDate.addressComponent.province }}{{ positionDate.addressComponent.district }}
      </h3>
      <div class="bar" v-if="positionDate" style="display: flex;flex-direction: column">
        <!--        <p style="font-weight: bold;"> </p>-->
        <p style="width: 70%">
          {{ positionDate.addressComponent.street }}{{ positionDate.addressComponent.streetNumber }}
        </p>
        <!--        <img src="../assets/posion.png" style="    width: 100px;-->
        <!--    position: absolute;-->
        <!--    right: 13px;" alt="" @click="sendPosition"/>-->
      </div>
      <div class="item">
        <van-radio-group v-model="reportType" style="width: 100%; display: flex;justify-content: space-between">
          <p class="left" style="width: 45%;">事故类型：</p>
          <div style="width: 55%;display: flex;justify-content: left;float: right">
            <van-radio name="0">事故</van-radio>
            <van-radio name="1" style="margin-left: 30px">故障</van-radio>
          </div>
        </van-radio-group>
      </div>
      <div class="item" style="padding: 9px 0 7px 0">
        <p class="left">联系人电话</p>
        <input
          type="tel"
          style="border: 1px solid #2E82FE;
                  height: 30px;
                  padding-left: 4px;
                  border-radius: 4px;"
          v-model="phone"
          class="center"
          placeholder="非车主本人报案时填写"
        />
      </div>
      <div class="item">
        <p class="left">24小时救援电话</p>
        <p class="center">{{ pageDate.fsRescuePhone }}</p>
        <p class="right" @click="sendPosition">
          <a :href="'tel:' + pageDate.fsRescuePhone"><img src="../assets/tel.png" alt=""/></a>
        </p>
      </div>
      <div class="item">
        <p class="left">事故理赔</p>
        <p class="center">{{ pageDate.fsContactPhone }}</p>
        <p class="right" @click="sendPosition">
          <a :href="'tel:' + pageDate.fsContactPhone"><img src="../assets/tel.png" alt=""/></a>
        </p>
      </div>
      <div class="item">
        <p class="left">保险公司</p>
        <p class="center">{{ pageDate.icName }} {{ pageDate.icPhone }}</p>
        <p class="right" @click="sendPosition">
          <a :href="'tel:' + pageDate.icPhone"><img src="../assets/tel.png" alt=""/></a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
// import { AMapManager } from 'vue-amap';
import { Toast } from 'vant'
import axios from 'axios'
import car from './../assets/car.png'
var map, geolocation, marker
export default {
  data: function() {
    return {
      x: 0,
      y: 0,
      pageDate: '',
      positionDate: '',
      phone: '',
      customerPhone: '',
      reportType: '0', // 0事故 1故障
    }
  },
  mounted() {
    //   https://test.4s.store.yiyu-info.com/upInfo?code=F556B0FC3F&type=0&fsid=c11556bc0225d644c6f2581fc07c6c66
    const that = this
    if (this.$route.query.type == 1 || this.$route.query.type == 0) {
      axios.get(httpUrl + `/fs/sc/orcode/info?code=${this.$route.query.code}`).then((res) => {
        if (res.data.code === 200) {
          if (res.data.data.status == 0) {
            debugger
            that.$router.push({
              path: '/register',
              query: {
                code: that.$route.query.code,
                type: that.$route.query.type,
                fsId: that.$route.query.fsid,
              },
            })
          }
        }
      })
    }
    this.inits()
  },

  methods: {
    inits() {
      map = new AMap.Map('container', {
        zoom: 16,
        resizeEnable: true,
        mapStyle: 'light',
      })
      // this.phone = this.$route.query.phone;
      // const code = window.location.href.split('#')[1];
      // axios.get(httpUrl + `/fs/sc/by/licensePlate?licensePlate=${this.$route.query.licensePlate}`)
      this.init(this.$route.query.phone)
      this.getMap()
    },
    sendPosition() {
      const addr = this.positionDate.formattedAddress

      /*
       * todo
       * */
      // this.phone
      const customerPhone = this.customerPhone

      const licensePlate = this.$route.query.licensePlate
      console.log(this.positionDate.position.R)
      console.log(this.positionDate.position.Q)
      console.log('=-=-=-==-=-=')

      let longitudeAndLatitude =
        (this.positionDate && this.positionDate.position && this.positionDate.position.R) ||
        '' + ',' + (this.positionDate && this.positionDate.position && this.positionDate.position.Q) ||
        ''
      longitudeAndLatitude = this.positionDate.position.R + ',' + this.positionDate.position.Q
      axios.post(httpUrl + `/fs/sc/sendReportSMS`, {
        addr,
        customerPhone,
        licensePlate,
        longitudeAndLatitude,
        contactPhone: this.phone || customerPhone,
        reportType: this.reportType,
      })
    },
    init(e) {
      // const that = this
      // axios.get(httpUrl + `/fs/sc/by/code?code=${this.$route.query.code}`).then((res) => {
      //   if (res.data.code === 200) {
      //     that.pageDate = res.data.data
      //     that.customerPhone = res.data.data.customerPhone
      //   } else {
      //     Toast(res.data.msg)
      //     setTimeout(() => {
      //       this.$router.push({
      //         path: '/register',
      //       })
      //     }, 2000)
      //   }
      // })
    },

    addMarker(x, y, data) {
      console.log('===++++====')
      console.log('---点点----')
      console.log(data)
      console.log(this.pageDate.createTime)
      var content = `<div style="background: url(${car}) no-repeat;width: 51px;height: 25px;position: relative">
                            <div style="display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;position:absolute;border-radius:6px;
                                left:-75px;top:-90px;background: #2E82FE;width: 200px;height: 50px">
                              <span style="display: block;font-size: 12px;color: #fff;font-weight: bold;padding: 0 10px">${data.formattedAddress}</span>
<!--                              <span style="display: block;font-size: 12px;color: #fff">${this.pageDate.createTime}</span>-->
                              <p style="position: absolute;left: 100px;top: 50px;width: 3px;height: 30px;display:inline-block;background: #2E82FE;">
                              <i style="left:-2px;display: inline-block;width: 7px;height: 7px;border-radius: 7px;position: absolute;bottom: 0;background: #2E82FE;"></i></p>
                            </div>
                        </div>`
      marker = new AMap.Marker({
        content,
        // icon: car,
        // size: new AMap.Size(40, 50),
        // imageSize: new AMap.Size(40, 50),
        position: [x, y],
        offset: new AMap.Pixel(-13, -30),
      })
      marker.setMap(map)
      map.setCenter([x, y])
    },
    getMap() {
      const that = this
      Toast.loading({
        message: '定位中...',
        forbidClick: true,
      })
      var options = {
        showButton: true, //是否显示定位按钮
        buttonPosition: 'LB', //定位按钮的位置
        showMarker: true, //是否显示定位点
        markerOptions: {
          offset: new AMap.Pixel(-18, -36),
          content: `
              <div style="position: relative;width: 1px;height: 1px;display: none">
                <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;position:absolute;border-radius:6px;left:-100px;top:-100px;background: #2E82FE;width: 200px;height: 50px">
                  <span style="display: block;font-size: 12px;color: #fff">新湖大厦</span>
                  <span style="display: block;font-size: 12px;color: #fff">2021-09-01</span>
                  <p style="position: absolute;left: 100px;top: 50px;width: 3px;height: 30px;display:inline-block;background: #2E82FE;">
                  <i style="left:-2px;display: inline-block;width: 7px;height: 7px;border-radius: 7px;position: absolute;bottom: 0;background: #2E82FE;"></i></p>
                </div>
                <img src= ${car}
                style="position:absolute;left:-36px;top:-18px;width:78px;height:36px"/>
              </div>
            `,
        },
        zoomToAccuracy: true,
        showCircle: false, //是否显示定位精度圈
      }
      AMap.plugin(['AMap.Geolocation'], function() {
        var geolocation = new AMap.Geolocation(options)
        map.addControl(geolocation)
        geolocation.getCurrentPosition()
        AMap.event.addListener(geolocation, 'complete', onComplete) //返回定位信息
        AMap.event.addListener(geolocation, 'error', onError) //返回定位出错信息
      })
      // //解析定位结果
      function onComplete(data) {
        var str = []
        str.push(data.position.getLat())
        str.push(data.position.getLng())
        that.addMarker(data.position.R, data.position.Q, data)
        that.positionDate = data
        Toast.clear()
      }
      //解析定位错误信息
      function onError(data) {
        alert('定位失败')
        Toast.clear()
      }
    },
  },
}
</script>
<style scoped>
.amap-demo {
  height: 100vh !important;
  width: 100vw !important;
}
.content {
  position: absolute;
  left: 3%;
  box-sizing: border-box;
  z-index: 99999;
  width: 94%;
  background: #fff;
  border-radius: 12px;
  bottom: 15px;
  font-size: 14px;
  padding: 15px;
}
.content h3 {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 4px;
}
.content .bar {
  display: flex;
  justify-content: space-between;
}
.content .bar p {
  text-align: left;
  font-size: 16px;
}
.posi-box {
  width: 79px;
  height: 16px;
}
.item {
  display: flex;
  font-size: 14px;
  padding-top: 5px;
  border-top: 1px solid #efefef;
  margin-top: 5px;
  align-items: center;
}
.item .left {
  font-size: 16px;
  width: 45%;
  text-align: left;
}
.item .center {
  font-size: 16px;
  width: 55%;
}
.item .right {
  font-size: 16px;
  width: 20%;
  text-align: right;
}
.item .right img {
  width: 27px;
  height: 27px;
}
</style>
